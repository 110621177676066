module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"lordsofwater","accessToken":"MC5ZMDFsTGhFQUFDRUF5ZnB2.a3wbAe-_ve-_ve-_ve-_vUrvv73vv70YQe-_ve-_vTHvv73vv70FCDHvv70RDyQce--_vTzvv73vv73vv70"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"/workspace/src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d64f904b854b39c431e172790b017a35"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
